<template>
  <div>
    <div class="container py-3">
      
      <!-- 1/4 布局 -->
      <a-card :tab-list="tabList" :active-tab-key="noTitleKey" :bordered="false" @tabChange="key => onTabChange(key, 'noTitleKey')"
        :headStyle="{ backgroundColor: '#fafafa' }" :bodyStyle="{ backgroundColor: '#fafafa' }">
        <div v-if="spinValue" style="display: flex; justify-content: center; align-items: center; height: 900px;">
        <a-spin>
          <a-icon slot="indicator" type="loading" style="font-size: 50px" />
        </a-spin>
      </div>
      <div v-else>
        <div v-if="noTitleKey === '0'" class="map">
          <div>
            <!-- 图文列表 -->
            <a-row type="flex" justify="space-between" class="pic-doc" v-for="item in news" :key=item.id>
              <a-col :span="6" @click="onclick(item.linkUrl)">
                <!-- <div> -->
                <img class="pic" style="border-radius: 5px;" alt="item.title"
                  :src="item.picUrl" />
                <!-- </div> -->
              </a-col>
              <a-col :span="18" @click="onclick(item.linkUrl)" class="doc">
                <div class="line">
                  <span class='doc-title'>{{ item.title }}</span>
                  <span class='doc-credat'>{{ item.creatDate }}</span>
                </div>

                <!-- <div class="doc-content"
             v-html="item.content"></div> -->
              </a-col>
              <a-divider style="margin: 15px 0px;" />
            </a-row>
          </div>
          <!-- <a-pagination style="margin-top: 20px;float: right;" :default-current="6" :total="500" /> -->
        </div>
        <div v-if="noTitleKey === '1'" class="map">
          <div>
            <!-- 图文列表 -->
            <a-row type="flex" justify="space-between" class="pic-doc" v-for="item in news" :key=item.id>
              <a-col :span="6" @click="onclick(item.linkUrl)">
                <!-- <div> -->
                <img class="pic" style="border-radius: 5px;" alt="item.title"
                  :src="item.picUrl" />
                <!-- </div> -->
              </a-col>
              <a-col :span="18" @click="onclick(item.linkUrl)" class="doc">
                <div class="line">
                  <span class='doc-title'>{{ item.title }}</span>
                  <span class='doc-credat'>{{ item.creatDate }}</span>
                </div>

                <!-- <div class="doc-content"
             v-html="item.content"></div> -->
              </a-col>
              <a-divider style="margin: 15px 0px;" />
            </a-row>
          </div>
          <!-- <a-pagination style="margin-top: 20px;float: right;" :default-current="6" :total="1" /> -->
        </div>
        <div v-if="noTitleKey === '2'" class="map">
          <div>
            <!-- 图文列表 -->
            <a-row type="flex" justify="space-between" class="pic-doc" v-for="item in news" :key=item.id>
              <a-col :span="6" @click="onclick(item.linkUrl)">
                <!-- <div> -->
                <img class="pic" style="border-radius: 5px;" alt="item.title"
                  :src="item.picUrl" />
                <!-- </div> -->
              </a-col>
              <a-col :span="18" @click="onclick(item.linkUrl)" class="doc">
                <div class="line">
                  <span class='doc-title'>{{ item.title }}</span>
                  <span class='doc-credat'>{{ item.creatDate }}</span>
                </div>

                <!-- <div class="doc-content"
             v-html="item.content"></div> -->
              </a-col>
              <a-divider style="margin: 15px 0px;" />
            </a-row>
          </div>
          <!-- <a-pagination style="margin-top: 20px;float: right;" :default-current="6" :total="20" /> -->
        </div>
      </div>

        
      </a-card>


    </div>
  </div>
</template>

<script>
import { getNewDetail } from "@/request/api";
export default {
  data() {
    return {
      news:[],
      tabList: [
        {
          key: '0',
          tab: '最新咨询',
        },
        {
          key: '1',
          tab: '房源咨询',
        },
        {
          key: '2',
          tab: '公告',
        },
      ],
      noTitleKey: '0',
      spinValue:true
    }
  },

  mounted() {
    this.getNews(this.noTitleKey)
  },


  methods: {
    onTabChange (key, type) {
        this[type] = key
        this.getNews(this.noTitleKey)
      },

    getNews(noTitleKey){
      this.spinValue=true
      getNewDetail({
        page:1,
        pageSize:20,
        style:noTitleKey
      }).then((res) => {
        console.log(res)
            if(res.data.code = "200"){
              this.news=res.data.data
            }
            this.spinValue=false
        });
    },
    onclick(url){
        // 使用 window.open 在新标签页中打开 URL
        window.open(url, '_blank');
        }

  },
}
</script>

<style scoped>
.disp-box {
  background-color: #fafafa;
  box-sizing: border-box;
  /* min-height: 400px; */
  color: #222;
}

.block-title {
  height: 60px;
}

.block-title .title {
  line-height: 60px;
  padding-left: 32px;
  font-size: 20px;
}

.block-title .read-more {
  line-height: 60px;
  padding-right: 32px;
  font-size: 14px;
  text-align: right;
  color: #f26c00;
  cursor: pointer;
}

.pic-doc {
  padding: 0px 32px;
}

.pic-doc .pic {
  height: 178px;
  width: 260px;
  object-fit: cover;
  object-position: center;
  cursor: pointer;
  transition: all 0.2s;
}

.pic-doc .pic:hover {
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.3);
  transform: scale(1.05);
}

.pic-doc .doc {
  cursor: pointer;
}

.pic-doc .doc:hover {
  color: #0060c9;
}

.pic-doc .doc .line {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.pic-doc .doc .line .doc-title {
  font-weight: 600;
  text-align: left;
  font-size: 20px;
}

.pic-doc .doc .line .doc-credat {
  color: #888;
}

.pic-doc .doc .doc-content {
  text-align: left;
  font-size: 12px;
  margin-top: 10px;
  text-indent: 2em;
  color: #444;
}

.doc-list {
  display: flex;
  /* float: left; */
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 32px;
  padding-top: 12px;
}

.doc-list .line {
  box-sizing: border-box;
  width: 48%;
  padding: 8px 6px 8px 0;
  border-bottom: 1px solid #d6d6d6;
  cursor: pointer;
  display: flex;
  text-align: left;
}

.doc-list .line .doc-credat {
  margin-left: auto;
  color: #888;
}

/* .doc-list .doc-list-title:nth-of-type(odd) {
  -ms-grid-column: 1;
}

.doc-list .doc-list-title:nth-of-type(even) {
  -ms-grid-column: 2;
} */

.doc-list .line:hover {
  color: #ff6c00;
}

.doc-list .line::before {
  content: ">";
  color: #94c2e9;
  padding-right: 1em;
}
</style>
